<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        An Erlenmeyer flask contains
        <number-value :value="massKHP" unit="\text{g}" />
        of
        <chemical-latex content="KHP" />
        (molar mass =
        <stemble-latex content="$204.22~\text{g/mol}).$" />
        When titrating the
        <chemical-latex content="KHP" />
        with
        <chemical-latex content="NaOH," />
        <number-value :value="volNaOH" unit="\text{mL}" />
        of
        <chemical-latex content="NaOH" />
        was added before the solution turned pink. What is the concentration of the
        <chemical-latex content="NaOH?" />
      </p>

      <calculation-input
        v-model="inputs.input1"
        class="mb-3"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex.vue';
import CalculationInput from '../inputs/CalculationInput.vue';
import NumberValue from '../NumberValue.vue';
import StembleLatex from '../StembleLatex.vue';

export default {
  name: 'ChemCPP1210Oct10Q1',
  components: {
    StembleLatex,
    CalculationInput,
    ChemicalLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    volNaOH() {
      return this.taskState.getValueBySymbol('volNaOH').content;
    },
    massKHP() {
      return this.taskState.getValueBySymbol('massKHP').content;
    },
  },
};
</script>
<style scoped></style>
